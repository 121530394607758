import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveInvoice } from "../../Network/InvoiceApi";
import { useParams } from "react-router-dom";

const BulkInvoiceModal = (props) => {
  //redux dispatch
  const dispatch = useDispatch();
  const params = useParams();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Tenants = useSelector((state) => state.cred.creds).filter(
    (tenant) => parseInt(tenant.tenant) === 1 && parseInt(tenant.deleted) === 0
  );
  const [Loading, setLoading] = useState(false);
  const Houses = useSelector((state) => state.house.houses).filter(
    (house) =>
      parseInt(house.deleted) === 0 &&
      (props.invoice.buildingLinker === "all" ||
        parseInt(props.invoice.buildingLinker) ===
          parseInt(house.buildingLinker)) &&
      Tenants.some(
        (tenant) => parseInt(tenant.houseLinker) === parseInt(house.linker)
      )
  );

  const Bills = useSelector((state) => state.bill.bills).filter(
    (bill) => parseInt(bill.deleted) === 0
  );
  const Buildings = useSelector((state) => state.building.buildings).filter(
    (building) => parseInt(building.deleted) === 0
  );

  const SaveInvoice = async (invoice) => {
    await addLiveInvoice(
      {
        ...invoice,
        live: 1,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let linker = Date.now();
    await Houses.map(async (house, index) => {
      let tenant = Tenants.find(
        (tenant) => parseInt(tenant.houseLinker) === parseInt(house.linker)
      );

      let invoice = {
        billLinker: props.invoice.billLinker,
        amount:
          props.invoice.billLinker === "rent"
            ? house.rent
            : props.invoice.amount,
        houseLinker: house.linker,
        tenantLinker: tenant?.linker,
        month: props.invoice.month,
        linker: linker + index,
        trace: linker + index,
        instLinker: ActiveCred.instLinker,
        credLinker: ActiveCred.linker,
        live: 0,
        status: 0,
        deleted: 0,
      };

      await SaveInvoice(invoice);

      return invoice;
    });
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            Bulk Invoice A Building/Hostel
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Invoice For:</label>
            <select
              className="rounded form-control"
              value={props.invoice.billLinker}
              onChange={(e) =>
                props.setInvoice({
                  ...props.invoice,
                  billLinker: e.target.value,
                })
              }
              required
            >
              <option value={""}>Select Invoice Category</option>
              <option value={"rent"}>Rent</option>
              {Bills.map((bill, index) => (
                <option key={index} value={bill.linker}>
                  {bill.name}
                </option>
              ))}
            </select>
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Invoice Month:</label>
            <input
              className="rounded form-control"
              placeholder={`select invoice month`}
              value={props.invoice.month}
              onChange={(e) =>
                props.setInvoice({
                  ...props.invoice,
                  month: e.target.value,
                })
              }
              required
              type={"month"}
            />
            <hr />
          </div>
          {Buildings.length > 0 && !params.houseLinker ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">Building/Hostel:</label>
              <select
                className="rounded form-control"
                value={props.invoice.buildingLinker}
                onChange={(e) =>
                  props.setInvoice({
                    ...props.invoice,
                    buildingLinker: e.target.value,
                  })
                }
                required
              >
                <option value={""}>Select Building</option>
                {Buildings.map((building) => (
                  <option value={building.linker} key={building.linker}>
                    {building.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            {props.invoice.billLinker !== "rent" ? (
              <>
                <label className="mb-2 text-capitalize">Invoice Amount:</label>
                <input
                  className="rounded form-control"
                  placeholder={`enter invoice amount`}
                  value={props.invoice.amount}
                  onChange={(e) =>
                    props.setInvoice({
                      ...props.invoice,
                      amount: e.target.value,
                    })
                  }
                  type="number"
                  required
                />
                <hr />
              </>
            ) : (
              <span>Houses rent amount shall be used</span>
            )}{" "}
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BulkInvoiceModal;
