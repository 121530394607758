import React from "react";
import {
	Buildings,
	CashCoin,
	CashStack,
	PersonBadge,
	Receipt,
	PersonWorkspace,
} from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const AdminDash = () => {
	const navigate = useNavigate();
	return (
		<>
			<div className="col-6 col-md-4 ">
				<div className="dashboard mb-2">
					<Dropdown
						className="no-caret"
						style={{ width: "100%" }}
						onClick={() => {
							window.scroll(0, 0);
							navigate(`staffs`);
						}}
					>
						<Dropdown.Toggle
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							variant="transparent"
						>
							<div>
								<div className="d-flex justify-content-center">
									<PersonBadge className="dash-icon" />
								</div>
								<p className="text-center dash-text">Staffs</p>
							</div>
						</Dropdown.Toggle>
					</Dropdown>
				</div>
			</div>
			<div className="col-6 col-md-4 ">
				<div className="dashboard mb-2">
					<Dropdown
						className="no-caret"
						style={{ width: "100%" }}
						onClick={() => {
							window.scroll(0, 0);
							navigate(`modes`);
						}}
					>
						<Dropdown.Toggle
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							variant="transparent"
						>
							<div>
								<div className="d-flex justify-content-center">
									<CashStack className="dash-icon" />
								</div>
								<p className="text-center dash-text">Payment Modes</p>
							</div>
						</Dropdown.Toggle>
					</Dropdown>
				</div>
			</div>
			<div className="col-6 col-md-4 ">
				<div className="dashboard mb-2">
					<Dropdown
						className="no-caret"
						style={{ width: "100%" }}
						onClick={() => {
							window.scroll(0, 0);
							navigate(`owners`);
						}}
					>
						<Dropdown.Toggle
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							variant="transparent"
						>
							<div>
								<div className="d-flex justify-content-center">
									<PersonWorkspace className="dash-icon" />
								</div>
								<p className="text-center dash-text">Property Owners</p>
							</div>
						</Dropdown.Toggle>
					</Dropdown>
				</div>
			</div>
			<div className="col-6 col-md-4 ">
				<div className="dashboard mb-2">
					<Dropdown
						className="no-caret"
						style={{ width: "100%" }}
						onClick={() => {
							window.scroll(0, 0);
							navigate(`buildings`);
						}}
					>
						<Dropdown.Toggle
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							variant="transparent"
						>
							<div>
								<div className="d-flex justify-content-center">
									<Buildings className="dash-icon" />
								</div>
								<p className="text-center dash-text">Buildings/Hostels</p>
							</div>
						</Dropdown.Toggle>
					</Dropdown>
				</div>
			</div>
			<div className="col-6 col-md-4 ">
				<div className="dashboard mb-2">
					<Dropdown
						className="no-caret"
						style={{ width: "100%" }}
						onClick={() => {
							window.scroll(0, 0);
							navigate(`bills`);
						}}
					>
						<Dropdown.Toggle
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							variant="transparent"
						>
							<div>
								<div className="d-flex justify-content-center">
									<Receipt className="dash-icon" />
								</div>
								<p className="text-center dash-text">Bills</p>
							</div>
						</Dropdown.Toggle>
					</Dropdown>
				</div>
			</div>

			<div className="col-6 col-md-4 ">
				{" "}
				<div className="dashboard mb-2">
					<Dropdown className="no-caret" style={{ width: "100%" }}>
						<Dropdown.Toggle
							style={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							variant="transparent"
						>
							<div>
								<div className="d-flex justify-content-center">
									<CashCoin className="dash-icon" />
								</div>
								<p className="text-center dash-text">Expenses</p>
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item>
								<p
									className="btn-link"
									onClick={() => {
										window.scroll(0, 0);
										navigate(`expensecats`);
									}}
								>
									Expense Categories
								</p>
							</Dropdown.Item>

							<Dropdown.Item>
								<p
									className="btn-link"
									onClick={() => {
										window.scroll(0, 0);
										navigate(`expenses`);
									}}
								>
									Expenses
								</p>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</>
	);
};

export default AdminDash;
