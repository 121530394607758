import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveInvoice, updateLiveInvoice } from "../../Network/InvoiceApi";
import { useParams } from "react-router-dom";

const InvoiceCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();
  const params = useParams();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Tenants = useSelector((state) => state.cred.creds).filter(
    (tenant) => parseInt(tenant.tenant) === 1 && parseInt(tenant.deleted) === 0
  );
  const [Loading, setLoading] = useState(false);
  const Houses = useSelector((state) => state.house.houses).filter(
    (house) =>
      parseInt(house.deleted) === 0 &&
      (props.invoice.buildingLinker === "all" ||
        parseInt(props.invoice.buildingLinker) ===
          parseInt(house.buildingLinker)) &&
      Tenants.some(
        (tenant) => parseInt(tenant.houseLinker) === parseInt(house.linker)
      )
  );
  const Bills = useSelector((state) => state.bill.bills).filter(
    (bill) => parseInt(bill.deleted) === 0
  );
  const Buildings = useSelector((state) => state.building.buildings).filter(
    (building) => parseInt(building.deleted) === 0
  );

  //edit invoice
  const EditInvoice = async () => {
    let { credLinker, trace, live, deleted } = props.invoice;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveInvoice(
      { ...props.invoice, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const SaveInvoice = async () => {
    let trace = Date.now();

    await addLiveInvoice(
      {
        ...props.invoice,
        instLinker: ActiveCred.instLinker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await SaveInvoice();
    } else {
      await EditInvoice();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Invoice</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Invoice For:</label>
            <select
              className="rounded form-control"
              value={props.invoice.billLinker}
              onChange={(e) =>
                props.setInvoice({
                  ...props.invoice,
                  billLinker: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            >
              <option value={""}>Select Invoice Category</option>
              <option value={"deposit"}>Deposit</option>
              <option value={"rent"}>Rent</option>
              {Bills.map((bill, index) => (
                <option key={index} value={bill.linker}>
                  {bill.name}
                </option>
              ))}
            </select>
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Invoice Month:</label>
            <input
              className="rounded form-control"
              placeholder={`select invoice month`}
              value={props.invoice.month}
              onChange={(e) =>
                props.setInvoice({
                  ...props.invoice,
                  month: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
              type={"month"}
            />
            <hr />
          </div>
          {Buildings.length > 0 && !params.houseLinker ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">Select Building:</label>
              <select
                className="rounded form-control"
                value={props.invoice.buildingLinker}
                onChange={(e) =>
                  props.setInvoice({
                    ...props.invoice,
                    buildingLinker: e.target.value,
                  })
                }
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={"all"}>All Buildings</option>
                {Buildings.map((building) => (
                  <option value={building.linker} key={building.linker}>
                    {building.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}

          {Houses.length > 0 && !params.houseLinker ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">House To Invoice:</label>
              <select
                className="rounded form-control"
                value={props.invoice.houseLinker}
                onChange={(e) => {
                  props.setInvoice({
                    ...props.invoice,
                    houseLinker: e.target.value,
                    tenantLinker: (
                      Tenants.find(
                        (tenant) =>
                          parseInt(tenant.houseLinker) ===
                            parseInt(e.target.value) &&
                          parseInt(tenant.tenant) === 1
                      ) || { linker: 0 }
                    ).linker,
                  });
                }}
                required
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select Invoice House</option>
                {Houses.map((house, index) => (
                  <option key={index} value={house.linker}>
                    {house.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize">Invoice Amount:</label>
            <input
              className="rounded form-control"
              placeholder={`enter invoice amount`}
              value={props.invoice.amount}
              onChange={(e) =>
                props.setInvoice({
                  ...props.invoice,
                  amount: e.target.value,
                })
              }
              type="number"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InvoiceCrud;
